var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"smart1"},[_c('div',{staticClass:"start"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require("./service_img/QFFZuObD.webp")),expression:"require(\"./service_img/QFFZuObD.webp\")"}],staticClass:"start_img"})]),_vm._m(0),_c('div',{staticClass:"smart1_1"})]),_c('div',{staticClass:"smart2"},[_c('div',{staticClass:"img-1"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('./service_img/img12.png')),expression:"require('./service_img/img12.png')"}],staticClass:"mind3"})]),_vm._m(1)]),_c('div',{staticClass:"smart3"},[_c('div',{staticClass:"mind10"},[_vm._v("\r\n       智能客服技术要素实现\r\n     ")]),_c('div',{staticClass:"ming11 mt60"},[_vm._m(2),_c('div',{staticClass:"img-3"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('./service_img/TnH73Snv.webp')),expression:"require('./service_img/TnH73Snv.webp')"}],staticClass:"mind15"})])]),_c('div',{staticClass:"ming11 mt30"},[_c('div',{staticClass:"img-4"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('./service_img/pmzfT7DH.webp')),expression:"require('./service_img/pmzfT7DH.webp')"}],staticClass:"mind21"})]),_vm._m(3)]),_c('div',{staticClass:"ming11 mt30"},[_vm._m(4),_c('div',{staticClass:"img-5"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('./service_img/HeKyswF2.webp')),expression:"require('./service_img/HeKyswF2.webp')"}],staticClass:"mind23"})])]),_c('div',{staticClass:"ming11 mt30"},[_c('div',{staticClass:"img-4"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('./service_img/LpzLRqcK.webp')),expression:"require('./service_img/LpzLRqcK.webp')"}],staticClass:"mind21"})]),_vm._m(5)])]),_c('div',[_c('Header'),_c('Footer')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bannert"},[_c('div',{staticClass:"mind1"},[_vm._v("\r\n       智能客服\r\n     ")]),_c('div',{staticClass:"mind2"},[_vm._v("\r\n         24小时在线智能客服机器人，轻松应对多个用户客服需求，了解景区业务全况\r\n     ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mind6"},[_c('div',[_c('div',{staticClass:"mind25"},[_vm._v("智能客服优点")]),_c('div',{staticClass:"mind27 mt50"},[_c('div',{staticClass:"mind8"}),_c('div',{staticClass:"mind9"},[_vm._v("7*24H全天候全通路秒级响应")])]),_c('div',{staticClass:"mind27 mt16"},[_c('div',{staticClass:"mind8"}),_c('div',{staticClass:"mind9"},[_vm._v("\r\n           知识库聚类沉淀，准确高效\r\n         ")])]),_c('div',{staticClass:"mind27 mt16"},[_c('div',{staticClass:"mind8"}),_c('div',{staticClass:"mind9"},[_vm._v("辅助人工客服，提升回复速度和准确率")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mind12"},[_c('div',{staticClass:"mind5"},[_vm._v("多种社交方式服务客户")]),_c('div',{staticClass:"mind14"},[_vm._v("\r\n            企业需要全面覆盖客户接触点，才能不流失咨询与商机\r\n          ")]),_c('div',{staticClass:"mind7 mt24"},[_c('div',{staticClass:"mind8"}),_c('div',{staticClass:"mind16"},[_vm._v("多通路沟通")])]),_c('div',{staticClass:"mind17"},[_vm._v("\r\n            覆盖微信在内的主流沟通渠道，独有QQ好友专属客服\r\n          ")]),_c('div',{staticClass:"mind7 mt16"},[_c('div',{staticClass:"mind8"}),_c('div',{staticClass:"mind16"},[_vm._v("线上线下打通")])]),_c('div',{staticClass:"mind17"},[_vm._v("\r\n           二维码发起对话并监测效果，打通线上线下场景\r\n          ")]),_c('div',{staticClass:"mind7 mt16"},[_c('div',{staticClass:"mind8"}),_c('div',{staticClass:"mind16"},[_vm._v("多模式沟通")])]),_c('div',{staticClass:"mind17"},[_vm._v("\r\n            融合电话、视频、在线沟通多种接待方式，无缝切换提升客服效率和客户体验\r\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mind20"},[_c('div',{staticClass:"mind5"},[_vm._v("大容量客户库管理商机")]),_c('div',{staticClass:"mind14"},[_vm._v("\r\n            基于社交好友关系，保护客户资源，持续跟踪需求，转化商机\r\n          ")]),_c('div',{staticClass:"mind7 mt24"},[_c('div',{staticClass:"mind8"}),_c('div',{staticClass:"mind16"},[_vm._v("客户库")])]),_c('div',{staticClass:"mind17"},[_vm._v("\r\n           海量客户库，保护客户资源不因为人员异动而流失\r\n          ")]),_c('div',{staticClass:"mind7 mt16"},[_c('div',{staticClass:"mind8"}),_c('div',{staticClass:"mind16"},[_vm._v("客户迁移")])]),_c('div',{staticClass:"mind17"},[_vm._v("\r\n           可将已有的客户名单迁移至客户库\r\n          ")]),_c('div',{staticClass:"mind7 mt16"},[_c('div',{staticClass:"mind8"}),_c('div',{staticClass:"mind16"},[_vm._v("客户归属")])]),_c('div',{staticClass:"mind17"},[_vm._v("\r\n            客户可归属专人跟进，提供专属服务促进商机转化\r\n          ")]),_c('div',{staticClass:"mind7 mt16"},[_c('div',{staticClass:"mind8"}),_c('div',{staticClass:"mind16"},[_vm._v("客户画像")])]),_c('div',{staticClass:"mind17"},[_vm._v("\r\n            通过多种维度，分析构建客户库画像，了解客户构成\r\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mind22"},[_c('div',{staticClass:"mind5"},[_vm._v("融合多通路智能连接客户")]),_c('div',{staticClass:"mind14"},[_vm._v("\r\n            聚合客户多种沟通方式为一体的工作台, 全面提升沟通效率\r\n          ")]),_c('div',{staticClass:"mind7 mt24"},[_c('div',{staticClass:"mind8"}),_c('div',{staticClass:"mind16"},[_vm._v("融合工作台")])]),_c('div',{staticClass:"mind17"},[_vm._v("\r\n            电话和在线沟通可并行或切换，支持线上线下多样场景\r\n          ")]),_c('div',{staticClass:"mind7 mt16"},[_c('div',{staticClass:"mind8"}),_c('div',{staticClass:"mind16"},[_vm._v("客户评分")])]),_c('div',{staticClass:"mind17"},[_vm._v("\r\n           基于访问行为、客户画像和机器学习算法，识别高价值客户\r\n          ")]),_c('div',{staticClass:"mind7 mt16"},[_c('div',{staticClass:"mind8"}),_c('div',{staticClass:"mind16"},[_vm._v("智能路由")])]),_c('div',{staticClass:"mind17"},[_vm._v("\r\n             智能分配高级客服对接优质客户，提升客户满意度\r\n          ")]),_c('div',{staticClass:"mind7 mt16"},[_c('div',{staticClass:"mind8"}),_c('div',{staticClass:"mind16"},[_vm._v("跨通路匹配")])]),_c('div',{staticClass:"mind17"},[_vm._v("\r\n             跨通路匹配客服与客户，避免重复沟通 (即将上线)\r\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mind20"},[_c('div',{staticClass:"mind5"},[_vm._v("主动营销增强转化优势")]),_c('div',{staticClass:"mind14"},[_vm._v("\r\n             服务即营销，增强主动营销能力，促进客户转化和复购\r\n          ")]),_c('div',{staticClass:"mind7 mt24"},[_c('div',{staticClass:"mind8"}),_c('div',{staticClass:"mind16"},[_vm._v("主动会话")])]),_c('div',{staticClass:"mind17"},[_vm._v("\r\n           根据网站停留时间、来访次数等，主动发起沟通\r\n          ")]),_c('div',{staticClass:"mind7 mt16"},[_c('div',{staticClass:"mind8"}),_c('div',{staticClass:"mind16"},[_vm._v("群发消息")])]),_c('div',{staticClass:"mind17"},[_vm._v("\r\n           依据标签、画像属性筛选，针对性群发消息触达客户\r\n          ")]),_c('div',{staticClass:"mind7 mt16"},[_c('div',{staticClass:"mind8"}),_c('div',{staticClass:"mind16"},[_vm._v("批量外呼")])]),_c('div',{staticClass:"mind17"},[_vm._v("\r\n            高效智能外呼，洞察客户价值，提升客户转化\r\n          ")]),_c('div',{staticClass:"mind7 mt16"},[_c('div',{staticClass:"mind8"}),_c('div',{staticClass:"mind16"},[_vm._v("个性化引导语")])]),_c('div',{staticClass:"mind17"},[_vm._v("\r\n            根据不同客户行为和属性，推送千人千面的引导语，提升客户咨询率\r\n          ")])])
}]

export { render, staticRenderFns }