<template>
<!-- zx 2021-6-11 -->
<div>
   <!-- <div class="smart1" v-lazy:background-image="require('./service_img/banner.png')"> -->
    <div class="smart1" >
   <div class="start">
       <img v-lazy='require("./service_img/QFFZuObD.webp")' class="start_img">
     </div>
     <div class="bannert">
     <div class="mind1">
       智能客服
     </div>
     <div class="mind2">
         24小时在线智能客服机器人，轻松应对多个用户客服需求，了解景区业务全况
     </div>
     </div>
      <div class="smart1_1"></div>
   </div>
   <div class="smart2">
    <div class="img-1">
       <img
     v-lazy="require('./service_img/img12.png')"
     class="mind3">
    </div>
     <div class="mind6">
       <div>
       <div class="mind25">智能客服优点</div>
       <div class="mind27 mt50">
         <div class="mind8"></div>
         <div class="mind9">7*24H全天候全通路秒级响应</div>
       </div>
        <div class="mind27 mt16">
         <div class="mind8"></div>
         <div class="mind9">
           知识库聚类沉淀，准确高效
         </div>
       </div>
        <div class="mind27 mt16">
         <div class="mind8"></div>
         <div class="mind9">辅助人工客服，提升回复速度和准确率</div>
       </div>
       </div>
     </div>
   </div>
   <div class="smart3">
     <div class="mind10">
       智能客服技术要素实现
     </div>
     <div class="ming11 mt60">
        <div class="mind12 ">
          <div class="mind5">多种社交方式服务客户</div>
          <div class="mind14">
            企业需要全面覆盖客户接触点，才能不流失咨询与商机
          </div>
          <div class="mind7 mt24">
            <div class="mind8"></div>
             <div class="mind16">多通路沟通</div>
          </div>
          <div class="mind17">
            覆盖微信在内的主流沟通渠道，独有QQ好友专属客服
          </div>
          <div class="mind7 mt16">
            <div class="mind8"></div>
             <div class="mind16">线上线下打通</div>
          </div>
          <div class="mind17">
           二维码发起对话并监测效果，打通线上线下场景
          </div>
          <div class="mind7 mt16">
            <div class="mind8"></div>
             <div class="mind16">多模式沟通</div>
          </div>
          <div class="mind17">
            融合电话、视频、在线沟通多种接待方式，无缝切换提升客服效率和客户体验
          </div>
        </div>
     <div class="img-3">
       <img  v-lazy="require('./service_img/TnH73Snv.webp')" class="mind15">
     </div>
     </div>

      <div class="ming11 mt30">
         <div class="img-4">
      <img  v-lazy="require('./service_img/pmzfT7DH.webp')" class="mind21">
     </div>
        
         <div class="mind20 ">
          <div class="mind5">大容量客户库管理商机</div>
          <div class="mind14">
            基于社交好友关系，保护客户资源，持续跟踪需求，转化商机
          </div>
          <div class="mind7 mt24">
            <div class="mind8"></div>
             <div class="mind16">客户库</div>
          </div>
          <div class="mind17">
           海量客户库，保护客户资源不因为人员异动而流失
          </div>
          <div class="mind7 mt16">
            <div class="mind8"></div>
             <div class="mind16">客户迁移</div>
          </div>
          <div class="mind17">
           可将已有的客户名单迁移至客户库
          </div>
          <div class="mind7 mt16">
            <div class="mind8"></div>
             <div class="mind16">客户归属</div>
          </div>
          <div class="mind17">
            客户可归属专人跟进，提供专属服务促进商机转化
          </div>
           <div class="mind7 mt16">
            <div class="mind8"></div>
             <div class="mind16">客户画像</div>
          </div>
          <div class="mind17">
            通过多种维度，分析构建客户库画像，了解客户构成
          </div>
        </div>
      </div>

      <div class="ming11 mt30">
        <div class="mind22 ">
          <div class="mind5">融合多通路智能连接客户</div>
          <div class="mind14">
            聚合客户多种沟通方式为一体的工作台,&nbsp;全面提升沟通效率
          </div>
          <div class="mind7 mt24">
            <div class="mind8"></div>
             <div class="mind16">融合工作台</div>
          </div>
          <div class="mind17">
            电话和在线沟通可并行或切换，支持线上线下多样场景
          </div>
          <div class="mind7 mt16">
            <div class="mind8"></div>
             <div class="mind16">客户评分</div>
          </div>
          <div class="mind17">
           基于访问行为、客户画像和机器学习算法，识别高价值客户
          </div>
          <div class="mind7 mt16">
            <div class="mind8"></div>
             <div class="mind16">智能路由</div>
          </div>
          <div class="mind17">
             智能分配高级客服对接优质客户，提升客户满意度
          </div>
          <div class="mind7 mt16">
            <div class="mind8"></div>
             <div class="mind16">跨通路匹配</div>
          </div>
          <div class="mind17">
             跨通路匹配客服与客户，避免重复沟通&nbsp;(即将上线)
          </div>
        </div>
         <div class="img-5">
      <img v-lazy="require('./service_img/HeKyswF2.webp')"  class="mind23">
     </div>
     
     </div>

     <div class="ming11 mt30">
         <div class="img-4">
     <img v-lazy="require('./service_img/LpzLRqcK.webp')" class="mind21">
     </div>
        
         <div class="mind20 ">
          <div class="mind5">主动营销增强转化优势</div>
          <div class="mind14">
             服务即营销，增强主动营销能力，促进客户转化和复购
          </div>
          <div class="mind7 mt24">
            <div class="mind8"></div>
             <div class="mind16">主动会话</div>
          </div>
          <div class="mind17">
           根据网站停留时间、来访次数等，主动发起沟通
          </div>
          <div class="mind7 mt16">
            <div class="mind8"></div>
             <div class="mind16">群发消息</div>
          </div>
          <div class="mind17">
           依据标签、画像属性筛选，针对性群发消息触达客户
          </div>
          <div class="mind7 mt16">
            <div class="mind8"></div>
             <div class="mind16">批量外呼</div>
          </div>
          <div class="mind17">
            高效智能外呼，洞察客户价值，提升客户转化
          </div>
           <div class="mind7 mt16">
            <div class="mind8"></div>
             <div class="mind16">个性化引导语</div>
          </div>
          <div class="mind17">
            根据不同客户行为和属性，推送千人千面的引导语，提升客户咨询率
          </div>
        </div>
      </div>
 
   </div>
<div>
  <Header></Header>
  <Footer></Footer>
  </div>
</div>

</template>
<script>
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
//引入jquery
export default {
components: {
    Header,
    Footer,
  },
}
</script>
<style  lang="scss" scoped>
/* 动画效果 */
@keyframes sport {
  0% {
    transform: translateY(80px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

  .smart1{
    height: 600px;
    background-repeat:  no-repeat;
    background-size: 100% 100%;
  }
  .start_img{
   height: 600px;
  width: 100%;
}
.start{
   height: 600px;
  width: 100%;
  display: flex;
    justify-content: center;
    align-items: center;
    img[lazy="loading"] {
		width: 200px;
		height: 200px;
	}
	// 实际图片大小
	img[lazy="loaded"] {
		 height: 600px;
  width: 100%;
	}
}
.bannert{
  z-index: 5;
   position: absolute;
    left: 361px;
    top: 230px;

}
  .smart1_1{
    position: absolute;
    top: 0;
    left: 0;
     height:600px;
    width:100%;
    background-color: rgba(0,0,0,.4);
  }
  .mind1{
    color: rgba(255, 255, 255, 1);
    font-size: 48px;
    //  margin-top: -370px;
    // padding-left: 360px;
    text-align: left;
  }
  .mind1{
    animation: sport 0.5s;
  transform: translateY(0px);
  }
  .mind2{
    color: rgba(255, 255, 255, 1);
    font-size: 24px;
    // padding-left: 360px;
    margin-top: 24px;
    text-align: left;
    width: 675px;
  }
  .mind2{
     animation: sport 0.5s;
  transform: translateY(0px);
  }
  .smart2{
    display: flex;
    justify-content: center;
    margin-top: 80px;
    margin-bottom: 100px;

  }
  .mind3{
    width: 496px;
    height: 381px;
  }
  .img-1{
     width: 496px;
    height: 381px;
     display: flex;
    justify-content: center;
    align-items: center;
    img[lazy="loading"] {
		width: 60px;
		height: 60px;
	}
	// 实际图片大小
	img[lazy="loaded"] {
		 width: 496px;
    height: 381px;
	}
  }
  .mind6{
  width: 704px;
  height: 381px;
  border-radius: 0 0 6px 6px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
  display: flex;
  /* align-items: center; */
  justify-content: center;
  }
  .mind25{
     color: rgba(51, 51, 51, 1);
  font-size: 32px;
  text-align: left;
  margin-top: 60px;
  }
  .mind5{
    color: rgba(51, 51, 51, 1);
  font-size: 32px;
  text-align: left;
  margin-top: 48px;
  margin-left: 48px;
  }
  .mind7{
    display: flex;
    margin-left: 48px;
    
  }
  .mind27{
    display: flex;
  }
  .mind8{
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #0963F9;
  margin-top: 10px;

  }
  .mind9{
    color: rgba(153, 153, 153, 1);
  font-size: 20px;
  margin-left: 16px;
  text-align: left;
  }
  .mt24{
    margin-top: 24px;
  }
  .mt50{
    margin-top: 50px;
  }
  .mt16{
    margin-top: 16px;
  }
  .smart3{
    background-color: #F6F8FB;
    padding-bottom: 100px;
    
  }
  .mind10{
    color: rgba(51, 51, 51, 1);
  font-size: 40px;
  padding-top: 80px;
  margin: auto;
  }
  .ming11{
    display: flex;
    justify-content: center;
    
  }
  .mt60{
    margin-top: 60px;
  }
  .mind12{
    width: 600px;
    height: 453px;
    border-radius: 0 0 6px 6px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 2px 2px 12px 4px rgba(0, 0, 0, 0.04);
  }
  .mind14{
    color: rgba(86, 86, 86, 1);
    font-size: 20px;
    text-align: left;
    margin-top: 8px;
    margin-left: 48px;
    width: 504px;
  }
  .mind16{
    color: rgba(86, 86, 86, 1);
  font-size: 20px;
  margin-left: 10px;
  }
  .mind17{
    color: rgba(153, 153, 153, 1);
  font-size: 20px;
  margin-left: 64px;
  width: 476px;
  text-align: left;
  margin-top: 5px;
  }
  .mind15{
    width: 600px;
    height: 453px;
  }
  .img-3{
    width: 600px;
    height: 453px;
    display: flex;
    justify-content: center;
    align-items: center;
    img[lazy="loading"] {
		width: 80px;
		height: 80px;
	}
	// 实际图片大小
	img[lazy="loaded"] {
		  width: 600px;
    height: 453px;
	}
  }
  .mt30{
    margin-top: 30px;
  }
  .mind20{
    width: 600px;
    height: 533px;
    border-radius: 0 0 6px 6px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 2px 2px 12px 4px rgba(0, 0, 0, 0.04);
  }
  .mind21{
    width: 600px;
    height: 533px;
  }
  .img-4{
     width: 600px;
    height: 533px;
    display: flex;
    justify-content: center;
    align-items: center;
    img[lazy="loading"] {
		width: 80px;
		height: 80px;
	}
	// 实际图片大小
	img[lazy="loaded"] {
		  width: 600px;
    height: 533px;
	}
  }
  .mind23{
    width: 600px;
    height: 561px;
  }
  .img-5{
    width: 600px;
    height: 561px;
     display: flex;
    justify-content: center;
    align-items: center;
    img[lazy="loading"] {
		width: 80px;
		height: 80px;
	}
	// 实际图片大小
	img[lazy="loaded"] {
		 width: 600px;
    height: 561px;
	}
  }
  .mind22{
     width: 600px;
    height: 561px;
    border-radius: 0 0 6px 6px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 2px 2px 12px 4px rgba(0, 0, 0, 0.04);
  }
</style>